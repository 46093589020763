<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h1>{{ $t('Edit FAQ Section Item') }}</h1>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.faq_sections.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Section Group Name" v-model="faq_section.section_group_name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Section Title" v-model="faq_section.section_title"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <text-editor
                                label="Section Content"
                                v-model="faq_section.section_content"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Section Order Group" v-model="faq_section.order_group"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Section Order" v-model="faq_section.order"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="orange"
                                large
                                value="Update"
                                @click="handleUpdate"
                            >
                                <span>Update</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import FaqSections from "@/models/admin/FaqSections";
    import TextEditor from "@/components/app/form/TextEditor";

    export default {
        name: "faq-sections-show",
        components: {
            TextEditor,
            AdminDashboardLayout,
        },
        data: function () {
            return {
                faq_section: {},
            }
        },
        methods: {
            async handleUpdate() {
                await FaqSections.$http.post(`/api/admin/faq-sections/${this.$route.params.id}`, this.faq_section).then(() => {
                    this.$store.commit('showSnackBar', this.$t('messages.success'))
                    this.$router.push('/admin/faq-sections')
                }).catch((e) => {
                    this.errors = e.response.data.errors
                    this.$store.commit('showSnackBar', e.response.data.message)
                })
            },
        },
        async mounted() {
            await FaqSections.find(this.$route.params.id).then((res) => {
                this.faq_section = res.data
            })
        },
    }
</script>

<style lang="scss">
</style>
